import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/parteaguas.svg"
import twitter from "../images/twitter.svg"
import insta from "../images/insta.svg"
import face from "../images/face.svg"

const Header = () => (
  <div>
    <header
      className="header"
      data-sal="slide-up"
      data-sal-delay="500"
      data-sal-easing="ease"
      id="navbar"
    >
      <div className="header-sub">
        <Link to="/" className="header-sub__logo">
          <img src={logo} alt="Parteaguas" />
        </Link>
        <ul className="header-sub__menu">
          <li>
            <Link to="/podcast/" className="header-sub__menuLink">
              Podcast
            </Link>
          </li>
          <li>
            <Link to="/querida/" className="header-sub__menuLink">
              Querida Sensatez
            </Link>
          </li>
          <li>
            <Link to="/nosotros/" className="header-sub__menuLink">
              Nosotros
            </Link>
          </li>
        </ul>
      </div>
      <div className="header-socialMedia">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://facebook.com/parteaguasorg"
        >
          <img className="header-link" src={face} alt="Facebook" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://instagram.com/parteaguaspodcast"
        >
          <img className="header-link" src={insta} alt="Instagram" />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/parteaguasorg"
        >
          <img className="header-link" src={twitter} alt="Twitter" />
        </a>
      </div>
    </header>
    <div id="header" className="headerResp">
      <Link to="/">
        <img src={logo} className="navbarLogo" alt="Parteaguas" />
      </Link>
      <input className="menu-btn" type="checkbox" id="menu-btn" />
      <label className="menu-icon" htmlFor="menu-btn">
        <span className="navicon"></span>
      </label>
      <ul className="menu">
        <li>
          <Link to="/podcast/">Podcast</Link>
        </li>
        <li>
          <Link to="/querida/">Querida Sensatez</Link>
        </li>
        <li>
          <Link to="/nosotros/">Nosotros</Link>
        </li>
        <li>
          <div className="headerResp-icons">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://facebook.com/parteaguasorg"
            >
              <img className="header-link" src={face} alt="Facebook" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://instagram.com/parteaguaspodcast"
            >
              <img className="header-link" src={insta} alt="Instagram" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/parteaguasorg"
            >
              <img className="header-link" src={twitter} alt="Twitter" />
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
